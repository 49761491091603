export let AppName = 'Collega';

export let AdM_Home = 'div-gpt-ad-1671563468153-0'
export let AdM_Notifications = 'div-gpt-ad-1671564637798-0'


export let SkillThreshold = 1

export enum FormFields {
  type = 'type',
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  password = 'password',
  password1 = 'password1',
  password2 = 'password2',
  birth_date = 'birth_date',
  fiscal_code = 'fiscal_code',
  phone = 'phone',
  terms = 'terms',
  privacy = 'privacy',
  gdpr = 'gdpr',
  is_delayed = 'is_delayed',

  email_enabled = 'email_enabled',
  notifications_enabled = 'notifications_enabled',

  profile = 'profile',
  picture = 'picture',
  city = 'city',
  city2 = 'city2',

  skills = 'skills',

  // Profile professional info
  vat = 'vat',
  pec = 'pec',
  register_number = 'register_number',
  coa_office = 'coa_office',

  // Profile law firm
  lawfirm = 'lawfirm',
  name = 'name',
  address = 'address',
  lawyer = 'lawyer',
  description = 'description',
  fax = 'fax',
  zip = 'zip',

  //
  resourcetype = 'resourcetype',
  part1 = 'part1',
  part2 = 'part2',
  activity = 'activity',
  office = 'office',
  notice = 'notice_days',
  judge = 'judge',
  status = 'status',
  date = 'date',
  start = 'start',
  end = 'end',
  nrg = 'nrg',
  notes = 'notes',
  expense1 = 'expense1',
  expense2 = 'expense2',
  expense3 = 'expense3',
  allDay = 'all_day',

  // Request
  section = 'section',
  datetime = 'datetime',
  money = 'money',
  subject = 'subject',
  skill = "skill",
  only_aiga = 'only_aiga',
  only_certified = 'only_certified',
}

export enum RoleType {
  LAWYER = 'LAWYER',
  ESTABLISHED = 'ESTABLISHED',
  PRACTICING = 'PRACTICING',
  QUALIFIED = 'QUALIFIED'
}

export enum MonthType {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEPT = 'SEPT',
  OCT = 'OCT ',
  NOV = 'NOV',
  DEC = 'DEC ',
}

export enum ResourceType {
  Hearing = 'Hearing',
  Appointment = 'Appointment',
  Fulfillment = 'Fulfillment',
  Notice = 'Notice',
  News = 'News'
}

export enum EventStatus {
  TODO = 'TODO',
  DONE = 'DONE',
  POSTPONED = 'POSTPONED'
}

export enum NoticeType {
  _1 = 'Il giorno prima',
  _2 = 'Due giorni prima',
  _7 = 'Una settimana prima',
  _14 = 'Due settimane prima'
}

export enum PartsType {
  parts1 = 'parts1',
  parts2 = 'parts2'
}


export enum ExternalLinks {
  Support = 'mailto:info@collegaonline.it?subject=Feedback AIGA: Agenda Legale&body=Caro team dell\' applicazione AIGA: Agenda Legale, ho utilizzato la vostra applicazione, e desidererei condividere il mio feedback: ',
  Terms = 'https://collegaonline.it/informazioni-legali/',
  Privacy = 'https://www.iubenda.com/privacy-policy/7874198',
  iskilled = 'https://www.iskilled.net',
  fp4 = '/web/courses/home/',
  CollegaWebsite = 'https://collegaonline.it',
  PaymentStart = '/web/payments/collect/',
  CollegaShop = 'http://shop.collegaonline.it',

  // AppAddress = 'http://localhost:3000'
  AppAddress = 'https://app-staging.collegaonline.it',
  // AppAddress = 'https://app.collegaonline.it'
}


export enum DateFormat {
  profile = 'DD/MM/YYYY',
  server = 'YYYY-MM-DD',
  fullMonth = 'MMMM',
  month = 'MM',
  dayShort = 'ddd',
  dayWithNumber = 'dddd D',
  dayWithNumberAndMounth = 'dddd D MMMM',
  dayWithNumberAndMounthAndYear = 'dddd D MMMM YYYY',
  dayNumber = 'D',
  timeMicro = 'HH:mm:ss.SSSSSS',
  timeSecond = 'HH:mm:ss',
  time = 'HH:mm',
  full = 'DD MMMM YYYY',
  fullCalendar = 'YYYY-MM-DD HH:mm'
}


// ACTIVITIES

export enum DemandsStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID',
  ANNULLED = 'ANNULLED',
  EXPIRED = 'EXPIRED'
}

export enum CandidatesStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DEAD = 'DEAD'
}

export enum ManagementsStatus {
  ALL = 'ALL',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID'
}

// SUBSCRIPTIONS | CARD TYPES
export enum SubscriptionType {
  PROFESSIONAL = "professional",
  GOLD = "gold",
  SKILLS = "additional_skills",
}

export enum SubscriptionInfoType {
  PROFESSIONAL = "professional",
  GOLD = "gold",
  SKILLS = "competenze",
}


export enum SkillsType {
  PROFESSIONAL = "professional",
  BASIC = "basic",
  SKILLS = "specific",
}

export let provinces = [
  { "name": "Agrigento", "code": "AG" },
  { "name": "Alessandria", "code": "AL" },
  { "name": "Ancona", "code": "AN" },
  { "name": "Aosta", "code": "AO" },
  { "name": "Arezzo", "code": "AR" },
  { "name": "Ascoli Piceno", "code": "AP" },
  { "name": "Asti", "code": "AT" },
  { "name": "Avellino", "code": "AV" },
  { "name": "Bari", "code": "BA" },
  { "name": "Barletta-Andria-Trani", "code": "BT" },
  { "name": "Belluno", "code": "BL" },
  { "name": "Benevento", "code": "BN" },
  { "name": "Bergamo", "code": "BG" },
  { "name": "Biella", "code": "BI" },
  { "name": "Bologna", "code": "BO" },
  { "name": "Bolzano", "code": "BZ" },
  { "name": "Brescia", "code": "BS" },
  { "name": "Brindisi", "code": "BR" },
  { "name": "Cagliari", "code": "CA" },
  { "name": "Caltanissetta", "code": "CL" },
  { "name": "Campobasso", "code": "CB" },
  { "name": "Caserta", "code": "CE" },
  { "name": "Catania", "code": "CT" },
  { "name": "Catanzaro", "code": "CZ" },
  { "name": "Chieti", "code": "CH" },
  { "name": "Como", "code": "CO" },
  { "name": "Cosenza", "code": "CS" },
  { "name": "Cremona", "code": "CR" },
  { "name": "Crotone", "code": "KR" },
  { "name": "Cuneo", "code": "CN" },
  { "name": "Enna", "code": "EN" },
  { "name": "Fermo", "code": "FM" },
  { "name": "Ferrara", "code": "FE" },
  { "name": "Firenze", "code": "FI" },
  { "name": "Foggia", "code": "FG" },
  { "name": "Forlì-Cesena", "code": "FC" },
  { "name": "Frosinone", "code": "FR" },
  { "name": "Genova", "code": "GE" },
  { "name": "Gorizia", "code": "GO" },
  { "name": "Grosseto", "code": "GR" },
  { "name": "Imperia", "code": "IM" },
  { "name": "Isernia", "code": "IS" },
  { "name": "L'Aquila", "code": "AQ" },
  { "name": "La Spezia", "code": "SP" },
  { "name": "Latina", "code": "LT" },
  { "name": "Lecce", "code": "LE" },
  { "name": "Lecco", "code": "LC" },
  { "name": "Livorno", "code": "LI" },
  { "name": "Lodi", "code": "LO" },
  { "name": "Lucca", "code": "LU" },
  { "name": "Macerata", "code": "MC" },
  { "name": "Mantova", "code": "MN" },
  { "name": "Massa-Carrara", "code": "MS" },
  { "name": "Matera", "code": "MT" },
  { "name": "Messina", "code": "ME" },
  { "name": "Milano", "code": "MI" },
  { "name": "Modena", "code": "MO" },
  { "name": "Monza e della Brianza", "code": "MB" },
  { "name": "Napoli", "code": "NA" },
  { "name": "Novara", "code": "NO" },
  { "name": "Nuoro", "code": "NU" },
  { "name": "Oristano", "code": "OR" },
  { "name": "Padova", "code": "PD" },
  { "name": "Palermo", "code": "PA" },
  { "name": "Parma", "code": "PR" },
  { "name": "Pavia", "code": "PV" },
  { "name": "Perugia", "code": "PG" },
  { "name": "Pesaro e Urbino", "code": "PU" },
  { "name": "Pescara", "code": "PE" },
  { "name": "Piacenza", "code": "PC" },
  { "name": "Pisa", "code": "PI" },
  { "name": "Pistoia", "code": "PT" },
  { "name": "Pordenone", "code": "PN" },
  { "name": "Potenza", "code": "PZ" },
  { "name": "Prato", "code": "PO" },
  { "name": "Ragusa", "code": "RG" },
  { "name": "Ravenna", "code": "RA" },
  { "name": "Reggio Calabria", "code": "RC" },
  { "name": "Reggio Emilia", "code": "RE" },
  { "name": "Rieti", "code": "RI" },
  { "name": "Rimini", "code": "RN" },
  { "name": "Roma", "code": "RM" },
  { "name": "Rovigo", "code": "RO" },
  { "name": "Salerno", "code": "SA" },
  { "name": "Sassari", "code": "SS" },
  { "name": "Savona", "code": "SV" },
  { "name": "Siena", "code": "SI" },
  { "name": "Siracusa", "code": "SR" },
  { "name": "Sondrio", "code": "SO" },
  { "name": "Sud Sardegna", "code": "SU" },
  { "name": "Taranto", "code": "TA" },
  { "name": "Teramo", "code": "TE" },
  { "name": "Terni", "code": "TR" },
  { "name": "Torino", "code": "TO" },
  { "name": "Trapani", "code": "TP" },
  { "name": "Trento", "code": "TN" },
  { "name": "Treviso", "code": "TV" },
  { "name": "Trieste", "code": "TS" },
  { "name": "Udine", "code": "UD" },
  { "name": "Varese", "code": "VA" },
  { "name": "Venezia", "code": "VE" },
  { "name": "Verbano-Cusio-Ossola", "code": "VB" },
  { "name": "Vercelli", "code": "VC" },
  { "name": "Verona", "code": "VR" },
  { "name": "Vibo Valentia", "code": "VV" },
  { "name": "Vicenza", "code": "VI" },
  { "name": "Viterbo", "code": "VT" }
]