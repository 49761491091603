import React, { useEffect, useState } from "react";
import SubscriptionListItem from "../SubscriptionListItem";
import strings from "values/strings";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import Utility from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import UserService from "services/UserServices";
import InputAutosuggest from '../../common/AutoSuggest'
import { ISubscriptionReducer, setCityId, setPrice } from "store/reducers/SubscriptionReducer";

interface Props {
  subscriptionInfo: SubscriptionInfo;
}

const CollegaProfessional: React.FC<Props> = ({ subscriptionInfo }) => {
  const collega = strings.subscriptions.collega;

  const [cityPriceResponse, , cityPriceRequest] = UserService.useCityPriceAPI()
  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  const subscriptionInfoDescriptionArray = subscriptionInfo != undefined ? subscriptionInfo.description!.split('• ').filter(Boolean) : []
  const price = useSelector((state: ISubscriptionReducer) => state.subscription.price);

  const dispatch = useDispatch()

  const [lawfirmCity2, setLawfirmCity2] = useState<any>({
    query: '',
    object: undefined
  })


  useEffect(() => {
    if (subscriptionInfo) {
      dispatch(setPrice(Number(subscriptionInfo.price)))
    }
  }, [subscriptionInfo])


  useEffect(() => {
    if (cityPriceResponse) {
      dispatch(setPrice(Number(cityPriceResponse.price)))
    }
  }, [cityPriceResponse])

  useEffect(() => {
    if (lawfirmCity2.object != undefined) {
      dispatch(setCityId(lawfirmCity2.object.id))

      cityPriceRequest(lawfirmCity2.object.id.toString())
    }
  }, [lawfirmCity2])

  return (
    <div className="collega-details">
      <div className="collega-details__title">{subscriptionInfo.title}</div>
      <ul className="collega-details__list">

        {subscriptionInfo && subscriptionInfoDescriptionArray!.map((description, index) => (
          <SubscriptionListItem key={index} type={Utility.subscriptionType(subscriptionInfo)} text={description} />
        ))}
      </ul>
      <form className="collega-details__form">
        <div className="select-container">

          <InputAutosuggest
            hasIcon={false}
            label={'Scegli il secondo comune in cui operi'}
            placeholder={'Nessuna città'}
            suggestions={citiesResponse || []}
            onChange={(v) => {
              setLawfirmCity2({ query: v, object: undefined })
              // setIsLoading(false)
            }}
            onSelect={(data) => setLawfirmCity2({ query: data.name, object: data.value })}
            value={lawfirmCity2.query}
            onFetch={citiesRequest}
            error={''} />
        </div>
      </form>
      <span className="collega-details__hint">
        {collega.professional.select.hint}
      </span>
      <div className="collega-details__price-container">
        <span className="collega-details__price">
          {Utility.currency(price)}
          <span>/{collega.month} + {"IVA"}</span>
        </span>
        <span className="collega-details__renewal">
          {collega.automaticAnnualRenewal}
        </span>
      </div>
    </div>
  );
};

export default CollegaProfessional;
