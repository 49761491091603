import { useState } from "react";
import Network, { NetworkMethod } from "network/Network";
import { useDispatch } from "react-redux";
import { setIsLoading } from "store/reducers/AppReducer";
import { ExternalLinks } from "values/values";
import { FormData } from "components/subscription/collega/AccountConfirm";
import moment from "moment";


class SubscriptionService {

  static usePPSetupTokenAPI = (): [any, any, (auth_token: string, subscription_id: number, plan_id: number, payment_method: string, variant_id?: number, discount_code?: string, city_id?: number, SDI?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    let request = async (auth_token: string, subscription_id: number, plan_id: number, payment_method: string, variant_id?: number, discount_code?: string, city_id?: number, SDI?: string) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = 'https://api-m.sandbox.paypal.com/v3/vault/setup-tokens'
      let data = JSON.stringify({
        "payment_source": {
          "paypal": {
            "experience_context": {
              "shipping_preference": "SET_PROVIDED_ADDRESS",
              "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED",
              "brand_name": "Collega",
              "locale": "it-IT",
              "return_url": `${ExternalLinks.AppAddress}/subscription-activate?auth_token=${auth_token}&subscription_id=${subscription_id}&plan_id=${plan_id}&payment_method=${payment_method}&variant_id=${variant_id}&discount_code=${discount_code}&city_id=${city_id}&sdi=${SDI}`,
              "cancel_url": `${ExternalLinks.AppAddress}/subscription-activate`
            },
            "description": "MERCHANT",
            "usage_pattern": "IMMEDIATE",
            "usage_type": "MERCHANT",
            "customer_type": "CONSUMER"
          }
        }
      });
      let response = await Network.fetchPPAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  static useActivateSubscriptionAPI = (): [any, any, (subscription_id?: number, token?: string, plan_id?: number, payment_method?: string, variant_id?: number, discount_code?: string, city_id?: number, ccFormData?: any, SDI?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (subscription_id?: number, token?: string, plan_id?: number, payment_method?: string, variant_id?: number, discount_code?: string, city_id?: number, ccFormData?: any, SDI?: string) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = `payment-types/${subscription_id}/activate/`


      var data: any = {
        "plan_id": plan_id, // id piano required
        "payment_method": payment_method // opzioni: [paypal, credit_card, bank_transfer]
      }

      if (token) {
        data["setup_token"] = token
      }
      if (ccFormData) {
        let _ccFormData = JSON.parse(ccFormData)
        console.log("_ccFormData", _ccFormData)
        
        data["payment_details"] = { // required quando si paga con carta di credito
          "card": {
            "number": _ccFormData['cardNumber'],
            "expiry": moment(_ccFormData['expirationDate'], 'MM/YY').format('YYYY-MM') ,
            "name": _ccFormData['ccname'],
            "billing_address": { // opzionale
              "address_line_1": _ccFormData['address1'], // indirizzo 1
              "address_line_2": _ccFormData['address2'], // indirizzo 2
              "admin_area_1": _ccFormData['province'].code, // provincia abbreviata
              "admin_area_2": _ccFormData['province'].name, // provincia completa
              "postal_code": _ccFormData['postalCode'], // codice postale
              "country_code": _ccFormData['country_code'] // paese abbreviato
            }
          }
        }
      }

      if (variant_id) {
        data["payment_variant_id"] = variant_id
      }

      if (discount_code && discount_code !== 'undefined') {
        data["discount_code"] = discount_code
      }

      if (SDI && SDI !== 'undefined') {
        data["sdi"] = SDI
      }

      if (city_id) {
        data["city_id"] = city_id
      }
      // return
      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  static useConfirmAccountAPI = (): [any, any, (formData: FormData) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (formData: FormData) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = `payment-variants/professional-form/`

      var data: any = formData

      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


}

export default SubscriptionService



// const axios = require('axios');


// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: 'https://api-m.sandbox.paypal.com/v3/vault/setup-tokens',
//   headers: { 
//     'Content-Type': 'application/json', 
//     'Authorization': '••••••'
//   },
//   data : data
// };