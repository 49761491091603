import React, { useEffect, useState } from "react";
import Select from "components/common/Select";
import strings from "values/strings";
import SelectOption from "store/types/SelectionOption";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { Variant } from "store/types/Variant";
import { useDispatch, useSelector } from "react-redux";
import Utility from "utils/utils";
import { ISubscriptionReducer, setPrice, setSelectedVariant } from "store/reducers/SubscriptionReducer";


interface Props {
  subscriptionInfo: SubscriptionInfo
}

const CollegaCompetenze: React.FC<Props> = ({ subscriptionInfo }) => {
  const collega = strings.subscriptions.collega;

  const price = useSelector((state: ISubscriptionReducer) => state.subscription.price);

  const dispatch = useDispatch()

  const [selectedOption, setSelectedOption] = useState<SelectOption<Variant>>()

  const [originalPrice, setOriginalPrice] = useState<number>()

  useEffect(() => {
    if (selectedOption) {
      var price = 0
      if (selectedOption.value.price == null) {
        price = Number(subscriptionInfo.price)
      } else {
        price = Number(selectedOption.value.price)
      }

      dispatch(setPrice(price))
      dispatch(setSelectedVariant(selectedOption.value))
      
      setOriginalPrice(Number(subscriptionInfo.price) * Number(selectedOption.value.skills_number))
    }
  }, [selectedOption])


  const getOiginalPrice = (variant: Variant) => {
    if (variant.price != null) {
      return Number(subscriptionInfo.price) * Number(variant.skills_number)
    } else return null
  }


  useEffect(() => {
    if (!subscriptionInfo) return
    let defaultVariant = subscriptionInfo.variants!.filter((v) => v.price == subscriptionInfo.price)[0]
    setSelectedOption(new SelectOption(defaultVariant, `${defaultVariant.skills_number} competenza`))
  }, [subscriptionInfo])

  return (
    <div className="collega-details">

      <h1 style={{ marginBottom: 16 }}>{subscriptionInfo.title}</h1>
      <p style={{ marginTop: 0, marginBottom: 32 }}>{collega.competenze.description}</p>

      <form className="collega-details__form">
        <div className="select-container">
          <Select<SelectOption>
            label={collega.competenze.select.label}
            value={selectedOption}
            options={subscriptionInfo.variants!.map((item) => {
              return new SelectOption(item, `${item.skills_number} competenza`)
            })}
            onChange={(value: SelectOption) => setSelectedOption(value)}
          />
        </div>
      </form>
      <span className="collega-details__hint">
        {collega.competenze.select.hint}
      </span>
      <div className="collega-details__price-container">
        {selectedOption && getOiginalPrice(selectedOption!.value) && <span className="collega-details__price">
          {Utility.currency(originalPrice) != Utility.currency(price) && <div className="radio-button__original-price">{Utility.currency(originalPrice)}/{collega.month}</div>}
        </span>}
        <span className="collega-details__price">
          {Utility.currency(price)}
          <span>/{collega.month} + {"IVA"}</span>
        </span>
        <span className="collega-details__renewal">
          {collega.automaticAnnualRenewal}
        </span>
      </div>
    </div>
  );
};

export default CollegaCompetenze;
